import React from "react";
import { View } from "../atom/container";
import { DisabledInput } from "./input/DisabledInput";
import { Atom } from "../atom/index";

import { MdAccountCircle, MdAccountBox, MdPhoneIphone } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";

export default function StepOne() {
  const [Phone, setPhone] = React.useState("71332502");
  return (
    <View>
      <Atom.H1>Bienvenue</Atom.H1>
      <Atom.H5>
        Salut ! C’est votre 1ère connexion sur la plateforme, vérifez les
        informations ci-dessous si elles sont toutes correctes et mettre à jour
        votre numéro de téléphone{" "}
      </Atom.H5>

      <View style={{ width: "100%", marginTop: 20 }}>
        <DisabledInput
          editable={false}
          value="MAMADY S."
          icon={<MdAccountCircle size={25}></MdAccountCircle>}
        ></DisabledInput>
        <DisabledInput
          editable={false}
          value="KONATE"
          icon={<RiAccountCircleLine size={25}></RiAccountCircleLine>}
        ></DisabledInput>
        <DisabledInput
          editable={false}
          value="MEDECINE 4"
          icon={<FaChalkboardTeacher size={25}></FaChalkboardTeacher>}
        ></DisabledInput>
        <DisabledInput
          editable={true}
          value={Phone}
          onChange={(value) => setPhone(value.target.value)}
          icon={<MdPhoneIphone size={25}></MdPhoneIphone>}
        ></DisabledInput>
      </View>
    </View>
  );
}
