import { GoAlert } from "react-icons/go";
import { RiCheckboxCircleFill } from "react-icons/ri";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { Atom } from "../atom";

function Error(props) {
  return (
    <Atom.View
      background="#ffebee"
      style={{ borderRadius: 7, display: "flex", flexDirection: "row" }}
    >
      <GoAlert color={colors.RED}></GoAlert>
      <Atom.H3 style={{ color: colors.RED, paddingLeft: 10 }}>
        {" "}
        {props.children}
      </Atom.H3>
    </Atom.View>
  );
}

function Succes(props) {
  return (
    <Atom.View
      background="#ebfff4"
      style={{ borderRadius: 7, display: "flex", flexDirection: "row" }}
    >
      <RiCheckboxCircleFill
        color={colors.GREEN}
        size={20}
      ></RiCheckboxCircleFill>

      <Atom.H3 style={{ color: colors.GREEN, paddingLeft: 10 }}>
        {" "}
        {props.children}
      </Atom.H3>
    </Atom.View>
  );
}

export default function LogBox(props) {
  if (props.show == true) {
    switch (props.type) {
      case "success":
        return <Succes {...props}></Succes>;
        break;
      case "error":
        return <Error {...props}></Error>;
      default:
        break;
    }
  } else {
    return null;
  }
}
