import {
  SERVER_HOST,
  SERVER_PORT,
  SERVER_HTTP,
  SERVER_ENDPOINT,
} from "../constants/server";

const HOST = `${SERVER_HTTP}://${SERVER_HOST}:${SERVER_PORT}`;

export async function authentificate(identifiant, password) {
  const formData = new FormData();
  formData.append("identifiant", identifiant);
  formData.append("password", password);

  const request = await fetch(`${HOST}/${SERVER_ENDPOINT.authentification}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: "identifiant=" + identifiant + "&password=" + password,
  });

  const result = await request.json();
  return result;
}
