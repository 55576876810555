var data = {};

function Auth(state = false, action) {
  switch (action.type) {
    case "SET_AUTH_TRUE":
      return true;
      break;
    case "SET_AUTH_FALSE":
      return false;
      break;
    default:
      return state;
      break;
  }
}

export default Auth;
