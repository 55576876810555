import React from "react";
import { Atom } from "../atom";
import { View } from "../atom/container";

export default function StepThree() {
  return (
    <View>
      <Atom.H1 style={{ fontSize: 20, textAlign: "center", marginTop: 20 }}>
        Félicitations !
      </Atom.H1>
      <Atom.H5>
        Vous avez terminé avec la configuration de votre compte étudiant.
        Maintenant vous pouvez accéder à la plateforme en entrant votre numéro
        d’etudiant sur la carte d’étudiant et le nouveau mot de passe
      </Atom.H5>
    </View>
  );
}
