import { Button, EditTextFieldBtn } from "../atom/button";
import { A, H5, H1, H3 } from "../atom/heading";
import { Input } from "../atom/input";
import { Header } from "../atom/header";
import { Image } from "../atom/image";
import { View } from "./container";

export const Atom = {
  View,
  Button,
  A,
  H5,
  H1,
  H3,
  Input,
  Header,
  Image,
  EditTextFieldBtn,
};
