import styled from "styled-components";
import { colors } from "../../constants/colors";

export const Input = styled.input`
  width: 80%;
  height: 50px;
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  display: flex;
  --webkit-appearance: none;
  border-style: solid;
  padding-right: 10px;
  padding-left: 10px;
  border-color: #d7d7d7;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  transition: border-color, 400ms;

  :focus {
    border-color: ${colors.PRIMARY};
    color: ${colors.PRIMARY};
  }
`;

export const IconDiv = styled.div`
  width: 100%;
  height: 50px;
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  display: flex;
  margin: 5px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  transition: border-color, 400ms;
  align-items: center;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  position: relative;
`;
