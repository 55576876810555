import { Provider } from "react-redux";
import Login from "./containers/login";
import RootReducer from "./reducers";
import SignUp from "./screens/signup";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Provider store={RootReducer}>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login></Login>
          </Route>
          <Route exact path="/register">
            <SignUp></SignUp>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
