import React from "react";
import { View } from "../components/atom/container";
import { A, H1, H5 } from "../components/atom/heading";
import { Container } from "../components/login/container";
import { colors } from "../constants/colors";
import LoginCard from "../components/login/loginCard";
import { Header } from "../components/molecule/Header";

function Login() {
  return (
    <Container backgroundColor={colors.DARK}>
      <Header></Header>
      <View>
        <H5 color={colors.WHITE}>Authorization</H5>
        <H1 color={colors.WHITE}>Connexion</H1>
      </View>
      <LoginCard></LoginCard>
    </Container>
  );
}

export default Login;
