import Login from "../screens/login.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "../actions/index";

const mapStateToProps = (state) => ({
  authentification: state.Auth,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const setAuthentificationTrue = () => dispatch(Actions.setAuthTrue());
  const setAuthentificationFalse = () => dispatch(Actions.setAuthFalse());

  return {
    setAuthentificationTrue: () => {
      setAuthentificationTrue();
    },
    setAuthentificationFalse: () => {
      setAuthentificationFalse();
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
