import styled from "styled-components";

export const Card = styled.div`
  width: ${(props) => (props.width ? props.width : "300px")};
  background-color: white;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: height, 500ms;
  min-height: 200px;
  padding-left: 20px;
  padding-right: 20px;
`;
