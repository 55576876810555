import { Atom } from "../atom/index";
import FMos from "../../assets/FMOS.png";
import Rasere from "../../assets/RASERE WHITE.png";

export function Header() {
  return (
    <Atom.Header>
      <Atom.Image src={FMos} long={90} large={90}></Atom.Image>
      <Atom.Image src={Rasere} long={90} large={90}></Atom.Image>
    </Atom.Header>
  );
}
