import styled from "styled-components";

export const Image = styled.img`
  @media (max-width: 780px) {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }

  @media (min-width: 1200px) {
    width: ${(props) => 1.2 * props.long + "px"};
    height: ${(props) => 1.2 * props.large + "px"};
  }
`;
