import React from "react";
import { colors } from "../../constants/colors";
import { Atom } from "../atom/index";
import { Card } from "./card";
import LogBox from "../molecule/logBox";

import { Link } from "react-router-dom";
import { authentificate } from "../../api/authentification";

export default function LoginCard() {
  const [Loading, setLoading] = React.useState(false);
  const [AuthLogBox, setAuthLogBox] = React.useState(false);
  const [logMessage, setLogMessage] = React.useState({
    identifiant: "",
    type: "error",
  });
  const [identifiant, setIndentifiant] = React.useState();
  const [password, setPassword] = React.useState();

  const handleBtnClick = () => {
    setLoading(true);
    authentificate(identifiant, password).then((data) => {
      setLogMessage({ identifiant: data.identifiant, type: data.type });
      setAuthLogBox(true);
      setLoading(false);
    });
  };

  return (
    <Card>
      <LogBox type={logMessage.type} show={AuthLogBox}>
        {logMessage.type === "error"
          ? "Identifiant incorrecte"
          : "Identifiant correct"}
      </LogBox>
      <Atom.Input
        placeholder="N° de la carte d'étudiant"
        style={{ width: "95%", margin: 5 }}
        onChange={(e) => setIndentifiant(e.target.value)}
      ></Atom.Input>
      <Atom.Input
        placeholder="Mot de passe"
        type="password"
        style={{ width: "95%", margin: 5 }}
        onChange={(e) => setPassword(e.target.value)}
      ></Atom.Input>
      <Atom.Button
        title="Connecter"
        loading={Loading}
        onClick={() => handleBtnClick()}
      />

      <Atom.View style={{ paddingTop: 20 }}>
        <Atom.H5>Avez-vous oublié le mot de passe ?</Atom.H5>
        <Link to="/register">
          <Atom.A>Cliquez-ici</Atom.A>
        </Link>
      </Atom.View>
    </Card>
  );
}
