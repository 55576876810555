import React from "react";
import { Atom } from "../atom";
import { View } from "../atom/container";
import { DisabledInput } from "./input/DisabledInput";
import { GiPadlock } from "react-icons/gi";

export default function StepTwo() {
  return (
    <View>
      <Atom.H1 style={{ fontSize: 20, textAlign: "center", marginTop: 20 }}>
        Créer un nouveau mot de passe
      </Atom.H1>
      <Atom.H5>
        Pour une raison de sécurité, vous devriez créer votre propre mot de
        passe et laisser l’ancien
      </Atom.H5>
      <View style={{ width: "100%", marginTop: 20 }}>
        <DisabledInput
          editable={true}
          placeholder="Mot de Passe"
          icon={<GiPadlock size={25}></GiPadlock>}
        ></DisabledInput>
        <DisabledInput
          editable={true}
          placeholder="Confirmer"
          icon={<GiPadlock size={25}></GiPadlock>}
        ></DisabledInput>
      </View>
    </View>
  );
}
