import styled from "styled-components";

export const Header = styled.div`
  width: 90%;
  height: 90px;
  background-color: transparent;
  position: absolute;
  top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`;
