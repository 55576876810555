import styled from "styled-components";
import { colors } from "../../../constants/colors";
import { View } from "../../atom/container";

const StepRound = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.active ? "rgba(26, 203, 118, 0.19)" : "white"};
  border-width: 4px;
  border-style: solid;
  border-color: ${(props) => (props.active ? colors.GREEN : "#C4C4C4")};
  margin-left: 10px;
  margin-right: 10px;
`;

const StepBar = styled.div`
  width: 50px;
  height: 5px;
  border-radius: 1px;
  background-color: ${(props) =>
    props.active ? "rgba(26, 203, 118, 0.19)" : "#C4C4C4"};
`;

export default function Index(props) {
  switch (props.step) {
    case 1:
      return <Step1></Step1>;
      break;
    case 2:
      return <Step2></Step2>;
      break;
    case 3:
      return <Step3></Step3>;
      break;
    default:
      return <p>Error</p>;
      break;
  }
}

const Step1 = (props) => {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <StepRound active={true}> </StepRound>
      <StepBar></StepBar>
      <StepRound></StepRound>
      <StepBar></StepBar>
      <StepRound></StepRound>
    </View>
  );
};

const Step2 = (props) => {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <StepRound active={true}></StepRound>
      <StepBar active={true}></StepBar>
      <StepRound active={true}></StepRound>
      <StepBar></StepBar>
      <StepRound></StepRound>
    </View>
  );
};

const Step3 = (props) => {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <StepRound active={true}></StepRound>
      <StepBar active={true}></StepBar>
      <StepRound active={true}></StepRound>
      <StepBar active={true}></StepBar>
      <StepRound active={true}></StepRound>
    </View>
  );
};
