import React from "react";
import { Card } from "../login/card";
import StepTabs from "./Step/index";
import { Atom } from "../atom/index";
import StepOne from "./Step1";
import StepTwo from "./Step2";
import StepThree from "./step3";

export default function SignUpCard() {
  const [Step, setStep] = React.useState(1);

  const navigate = () => {
    if (Step !== 3) {
      setStep(Step + 1);
    }
  };

  const RenderStep = () => {
    switch (Step) {
      case 1:
        return <StepOne></StepOne>;
        break;
      case 2:
        return <StepTwo></StepTwo>;
        break;
      case 3:
        return <StepThree></StepThree>;
        break;
      default:
        break;
    }
  };
  return (
    <Card>
      <StepTabs step={Step}></StepTabs>
      <RenderStep></RenderStep>
      <Atom.Button
        loading={false}
        title="Suivant"
        onClick={() => navigate()}
      ></Atom.Button>
    </Card>
  );
}
