import * as types from "../constants/actionType";

export const setAuthTrue = () => {
  return {
    type: types.SET_AUTH_TRUE,
  };
};

export const setAuthFalse = () => {
  return {
    type: types.SET_AUTH_FALSE,
  };
};
