import { Card } from "../components/login/card";
import { Container } from "../components/login/container";
import SignUpCard from "../components/SignUp/SignUpCard";
import { colors } from "../constants/colors";

export default function SignUp() {
  return (
    <Container backgroundColor={colors.DARK}>
      <SignUpCard></SignUpCard>
    </Container>
  );
}
