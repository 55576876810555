import React from "react";

import { Atom } from "../../atom";
import { MdAccountCircle } from "react-icons/md";
import { IconDiv, Input } from "../../atom/input";

export function DisabledInput(props) {
  const [Editable, setEditable] = React.useState(false);
  return (
    <IconDiv>
      <div
        style={{
          width: 40,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 5,
        }}
      >
        {props.icon}
      </div>
      <Atom.Input
        autoFocus={true}
        style={{ width: "100%", height: "100%", paddingLeft: 45 }}
        value={props.value}
        disabled={Editable === true ? false : true}
        onDoubleClick={() => alert("hello")}
        {...props}
      ></Atom.Input>
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 5,
          right: 5,
        }}
      >
        <Atom.EditTextFieldBtn
          disabled={Editable}
          onClick={() =>
            props.editable === true ? setEditable(!Editable) : undefined
          }
        >
          Editer
        </Atom.EditTextFieldBtn>
      </div>
    </IconDiv>
  );
}
