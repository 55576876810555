import styled from "styled-components";

export const View = styled.div`
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${(props) =>
    props.background ? props.background : undefined};
`;
