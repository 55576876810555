import { combineReducers } from "redux";
import { createStore } from "redux";

import Auth from "./auth";

const RootReducer = combineReducers({ Auth });

const Store = createStore(
  RootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default Store;
