import styled from "styled-components";
import { colors } from "../../constants/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-height: 45em;
  }

  @media (min-width: 1200px) {
    height: 100vh;
  }
`;
