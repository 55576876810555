import React from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Btn = styled.div`
  width: 95%;
  height: 60px;
  background-color: ${colors.PRIMARY};
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Open Sans";
  font-weight: 600;
`;

export const EditTextFieldBtn = styled.div`
  background-color: ${(props) =>
    props.disabled == true ? colors.PRIMARY : "#dbdbdb"};
  padding: 10px;
  border-radius: 5px;
  color: ${(props) => (props.disabled == true ? colors.WHITE : "#a3a3a3")};
  cursor: cursor;
`;

export function Button(props) {
  return (
    <Btn {...props}>
      {props.loading === false ? props.title : null}
      <ClipLoader
        color="white"
        loading={props.loading}
        css={override}
        size={150}
      />
    </Btn>
  );
}
